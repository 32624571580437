<template>
  <v-layout>
    <v-container>
      <v-breadcrumbs class="pl-0" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <v-card outlined>
        <v-card-text>
          <p class="text-h6 mb-0">Calificaciones</p>
          <v-container>
            <v-form ref="formGradeSearch">
              <v-row class="d-flex flex-row">
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Semestre</p>
                  <v-autocomplete
                    v-model="formGroup.idSemester"
                    :items="semesters"
                    :filter="semesterFilter"
                    outlined
                    dense
                    item-text="nombreSemestre"
                    item-value="idSemestre"
                    label="Seleccione"
                    single-line
                    @change="findGroup"
                    :rules="semesterRules"
                    no-data-text="No hay semestres disponibles"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0">
                  <p class="mb-2">Grupo</p>
                  <v-autocomplete
                    v-model="formGroup.idGroup"
                    :items="groups"
                    :filter="groupFilter"
                    outlined
                    dense
                    item-text="nombreGrupo"
                    item-value="idGrupo"
                    label="Seleccione"
                    single-line
                    :rules="groupRules"
                    @change="changeGroup"
                    no-data-text="No hay grupos asignados con materia"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="3" md="3" class="py-0" align-self="center">
                  <v-btn
                    color="#1486bd"
                    dark
                    elevation="0"
                    :loading="loading"
                    @click="findStudents"
                  >
                    <span class="text-capitalize text-body">Buscar Grupo</span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
      <v-container v-if="loading" class="d-flex justify-center">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-container>
      <template v-if="students.length > 0">
        <v-data-table
          :search="search"
          :headers="headers"
          :items="students"
          item-key="idGrupoUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
        >
          <template v-slot:[`item.estado`]="{ item }">
            {{ item.estado ? "Activo" : "Inactivo" }}
          </template>
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="grey"
                  class="mr-2"
                  @click.stop="viewGrades(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-note-text</v-icon>
                </v-btn>
              </template>
              <span>Ver boletin</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </template>
      <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
        <span class="text-center">{{ textSnackbar }}</span>
      </v-snackbar>
      <v-dialog v-model="dialogGrades" max-width="750">
        <v-card>
          <v-card-title style="color: #1874bf"
            >Boletin de calificaciones
            <v-spacer />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-show="studentGrade.length > 0"
                  class="ml-3 mr-2"
                  color="primary"
                  fab
                  depressed
                  small
                  dark
                  v-bind="attrs"
                  v-on="on"
                  @click="printGrade"
                >
                  <v-icon>mdi-printer</v-icon>
                </v-btn>
              </template>
              <span>Imprimir Boletin</span>
            </v-tooltip>
          </v-card-title>
          <v-card-text>
            <v-container v-if="loadingGrades" class="d-flex justify-center">
              <v-progress-circular
                indeterminate
                color="primary"
              ></v-progress-circular>
            </v-container>
            <template v-if="studentGrade.length > 0">
              <v-data-table
                :headers="headersGrade"
                :items="studentGrade"
                hide-default-footer
                item-key="idGrupoMateria"
                class="elevation-1 font-weight-light caption"
                fixed-header
              >
              </v-data-table>
            </template>
          </v-card-text>
        </v-card>
      </v-dialog>
      <DialogDownload :dialog="dialogDownload" />
      <ReportStudentGrade
        v-show="false"
        ref="reportGrade"
        @downloadStop="dialogDownloadStop"
        :headers="headersPrint"
        :group="group"
        :student="studentData"
        :grades="studentGradePrint"
        :assistance="attendanceStudent"
      />
    </v-container>
  </v-layout>
</template>

<script>
import axios from "axios";
import ReportStudentGrade from "@/components/report/report-student-grade-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";
import { mapState } from "vuex";
export default {
  components: {
    ReportStudentGrade,
    DialogDownload,
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      search: "",
      group: {},
      dialogGrades: false,
      studentGrade: [],
      studentGradePrint: [],
      headersGrade: [],
      headersPrint: [],
      loadingGrades: false,
      dialogDownload: false,
      studentData: {},
      headers: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Telefono",
          value: "telefono",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      students: [],
      semesters: [],
      groups: [],
      formGroup: {
        idGroup: "",
        idSemester: "",
        idSubject: "",
      },
      semesterRules: [(value) => !!value || "Seleccione un semestre"],
      groupRules: [(value) => !!value || "Seleccione un grupo"],
      loading: false,
      loadingData: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      editedItem: {},
      items: [
        {
          text: "Calificaciones",
          disabled: true,
          href: "/grade",
        },
      ],
      turns: [],
      formGrade: {
        idGroup: "",
        idStudent: "",
        idTeacher: "",
        idSubject: "",
        dateIngress: "",
        partial: "",
        grade: "",
      },

      drills: 0,
      attendanceStudent: {},
    };
  },
  computed: {
    ...mapState(["user", "idDepartament"]),
    formTitle() {
      return this.editedIndex === -1
        ? "Registrar Calificacion"
        : "Editar Calificacion";
    },
  },
  watch: {
    dialogGrades(val) {
      val || this.closeGrade();
    },
  },
  created() {
    this.selectSemesterTeacher();
  },
  methods: {
    changeGroup(item) {
      this.students = [];

      const obj = this.groups.find((group) => group.idGrupo === item);
      this.drills = obj.cuotas;
      this.group.cuotas = obj.cuotas;
      this.group.nombreGrupo = obj.nombreGrupo;
    },
    dialogDownloadStop() {
      this.dialogDownload = false;
    },
    printGrade() {
      this.dialogDownload = true;
      this.$refs.reportGrade.generatePdf();
    },
    closeGrade() {
      this.dialogGrades = false;
      this.studentGrade = [];
      this.headersGrade = [];
      this.attendanceStudent = {};
    },
    async selectGradeStudentByIdGroup(idStudent) {
      this.studentGrade = [];
      this.studentGradePrint = [];
      try {
        let response = await axios.get(
          `students/selectGradeStudentByIdGroup/${this.formGroup.idGroup}`,
          { params: { idStudent: idStudent } }
        );
        if (response.status == 200) {
          if (response.data.subjects.length > 0) {
            this.studentGradePrint = response.data.subjects;
            response.data.subjects.map((subject, index) => {
              subject.nro = index + 1;
            });
            this.studentGrade = response.data.subjects;
          } else {
            this.notification("warning", "No se registro las materias");
          }
          this.loadingGrades = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingGrades = false;
      }
    },
    defineHeaders() {
      this.headersPrint.push({
        text: "Materia",
        value: "nombreMateria",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      });
      this.headersGrade.push(
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Materia",
          value: "nombreMateria",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        }
      );
      for (let i = 0; i < this.drills; i++) {
        this.headersGrade.push(
          {
            text: `Simulacro ${i + 1}`,
            value: `sim${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          },
          {
            text: `Examen ${i + 1}`,
            value: `exa${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          }
        );
        this.headersPrint.push(
          {
            text: `Simulacro ${i + 1}`,
            value: `sim${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          },
          {
            text: `Examen ${i + 1}`,
            value: `exa${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          }
        );
      }
    },
    viewGrades(item) {
      this.dialogGrades = true;
      this.studentData = {};
      this.studentData = Object.assign({}, item);
      this.defineHeaders();
      this.loadingGrades = true;
      this.selectGradeStudentByIdGroup(item.idUsuario);
      this.selectAttendanceStudent(this.formGroup.idGroup, item.idUsuario);
    },
    async selectAttendanceStudent(idGroup, idUser) {
      try {
        let response = await axios.get(
          `attendances/selectAttendanceStudent/${idGroup}/${idUser}`
        );
        if (response.status == 200) {
          this.attendanceStudent = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectStudentsByIdGroup(idGroup) {
      this.students = [];
      try {
        let response = await axios.get(
          `courses/selectStudentsByIdGroup/${idGroup}`,
          {
            params: {
              idTeacher: this.user.idUsuario,
              idSubject: this.formGroup.idSubject,
            },
          }
        );
        if (response.status == 200) {
          response.data.map((value, index) => {
            this.students.push({
              nro: index + 1,
              idUsuario: value.idUsuario,
              estudiante: value.estudiante,
              ci: value.ci,
              telefono: value.ci,
              email: value.email,
              s1: value.s1,
              s2: value.s2,
              s3: value.s3,
              s4: value.s4,
              s5: value.s5,
              s6: value.s6,
              nfin: (
                (Number(value.s1) +
                  Number(value.s2) +
                  Number(value.s3) +
                  Number(value.s4) +
                  Number(value.s5) +
                  Number(value.s6)) /
                this.drills
              ).toFixed(1),
            });
          });
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async selectCourseStudents(idGroup) {
      let url = `courses/selectCourseStudentsByIdGroup/${idGroup}/1`;
      this.loading = true;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.students = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    findStudents() {
      if (this.$refs.formGradeSearch.validate()) {
        this.students = [];
        this.selectCourseStudents(this.formGroup.idGroup);
      }
    },
    definePartial() {
      for (let i = 0; i < this.drills; i++) {
        this.partial.push({
          type: i + 1,
          name: `Simulacro ${i + 1}`,
        });
      }
    },
    groupFilter(item, queryText) {
      const textOne = item.nombreGrupo.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async selectGroupBySemester(idSemester) {
      try {
        let response = await axios.get(
          `groupTeachers/selectGroupByIdSemester/${this.idDepartament}/${idSemester}`
        );
        if (response.status == 200) {
          this.groups = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    findGroup(item) {
      this.groups = [];
      this.formGroup.idGroup = "";
      this.formGroup.idSubject = "";
      this.students = [];
      this.drills = 0;
      this.partial = [];

      this.selectGroupBySemester(item);
    },
    semesterFilter(item, queryText) {
      const textOne = item.nombreSemestre.toLowerCase();
      const searchText = queryText.toLowerCase();

      return textOne.indexOf(searchText) > -1;
    },
    async selectSemesterTeacher() {
      try {
        let response = await axios.get(
          `semesters/selectSemesterTeacher/${this.idDepartament}`
        );
        if (response.status == 200) {
          this.semesters = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },

    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>