var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"pdf",staticClass:"mx-5 mt-4 section"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-8"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-8 px-2 py-2 border-top border-left border-secondary"},[_c('p',{staticClass:"mb-0 font-weight-bold section"},[_vm._v(" Nombre: "),_c('span',{staticClass:"font-weight-light text-small section"},[_vm._v(_vm._s(_vm.student.apellidos + " " + _vm.student.nombres))])])]),_c('div',{staticClass:"col-4 px-2 py-2 border-top border-left border-secondary"},[_c('p',{staticClass:"mb-0 font-weight-bold section"},[_vm._v(" Grupo: "),_c('span',{staticClass:"font-weight-light text-small section"},[_vm._v(" "+_vm._s(_vm.group.nombreGrupo))])])])])]),_c('div',{staticClass:"col-4 border-top border-left border-right border-secondary"},[_c('div',{staticClass:"row justify-content-center pt-3"},[_c('img',{attrs:{"src":require('../../assets/blueLogo2.png'),"height":"90","alt":"..."}})])])]),_vm._m(1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 p-0"},[_c('table',{staticClass:"table table-sm table-bordered mb-0",attrs:{"cellspacing":"0"}},[_c('thead',{staticClass:"thead-dark"},[_c('tr',_vm._l((_vm.headers),function(i){return _c('th',{key:i,staticClass:"section",attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(i.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.grades),function(item){return _c('tr',{key:item.idGrupoMateria},_vm._l((_vm.headers),function(col,index){return _c('td',{key:col,class:`py-0 px-1 pt-1 pb-1 ${
                index === 0 ? 'text-left' : 'text-center'
              } section`,style:(`${
                index === 0 ? 'border-left: 1px solid #1486bd' : ''
              }`)},[_vm._v(" "+_vm._s(item[col.value])+" ")])}),0)}),0)])])]),_vm._m(2),_vm._m(3),_c('div',{staticClass:"row"},[_vm._m(4),_c('div',{staticClass:"col-9 border-left border-top border-right border-secondary"},[_c('p',{staticClass:"mb-0 text-center font-weight-bold h5 section"},[_vm._v(" SU ASISTENCIA ES DE: "+_vm._s(_vm.assistance.totalAssistance)+"% ")])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12 justify-content-center border-top border-left border-secondary"},[_c('p',{staticClass:"font-weight-bold mb-0 h5 text-center section"},[_vm._v(" INSTITUTO PREUNIVERSITARIO ")]),_c('p',{staticClass:"font-weight-bold mb-0 h5 text-center section"},[_vm._v(" \"ISAAC NEWTON\" ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 border border-secondary",staticStyle:{"height":"30px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 border-left border-secondary"},[_c('p',{staticClass:"mb-0 text-center font-weight-bold section"},[_vm._v("DIAGNOSTICO")])]),_c('div',{staticClass:"col-3 border-left border-secondary"},[_c('p',{staticClass:"mb-0 text-center section text-small"},[_vm._v(" MENOR A 30 PUNTOS = 20 ")])]),_c('div',{staticClass:"col-3 border-left border-secondary"},[_c('p',{staticClass:"mb-0 text-center section text-small"},[_vm._v(" MENOR A 50 PUNTOS = 40 ")])]),_c('div',{staticClass:"col-3 border-left border-right border-secondary"},[_c('p',{staticClass:"mb-0 text-center section text-small"},[_vm._v(" MAYOR A 50 PUNTOS = 60 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-3 border-left border-top border-secondary"},[_c('p',{staticClass:"mb-0 text-center font-weight-bold section"},[_vm._v("PMA")])]),_c('div',{staticClass:"col-3 border-left border-top border-secondary"},[_c('p',{staticClass:"mb-0 text-center section"},[_vm._v("APTO")])]),_c('div',{staticClass:"col-3 border-left border-top border-secondary"},[_c('p',{staticClass:"mb-0 text-center section"},[_vm._v("NO APTO")])]),_c('div',{staticClass:"col-3 border-left border-top border-right border-secondary"},[_c('p',{staticClass:"mb-0 text-center section"},[_vm._v("NIVELACION")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-3 border-left border-top border-secondary"},[_c('p',{staticClass:"mb-0 text-center font-weight-bold section"},[_vm._v(" ASISTENCIA AL 100% ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col-12 border border-secondary"},[_c('p',{staticClass:"mb-0 section"},[_vm._v("OBSERVACIONES:")])])])
}]

export { render, staticRenderFns }