<template>
  <v-container>
    <v-breadcrumbs class="pl-0" :items="items">
      <template v-slot:item="{ item }">
        <v-breadcrumbs-item :to="item.href" :disabled="item.disabled">
          {{ item.text }}
        </v-breadcrumbs-item>
      </template>
    </v-breadcrumbs>
    <v-card outlined tile class="pb-2">
      <v-card-title style="color: #1e88e5" class="ml-2 pb-0">
        {{ nombreGrupo }}
      </v-card-title>
      <v-card-text class="pb-0">
        <v-row class="ml-1">
          <v-col cols="12" sm="12" md="12">
            <p
              style="color: #4c4c4c"
              class="mb-1 subtitle-2 font-weight-medium"
            >
              Semestre:
              <span class="caption">{{ semester.nombreSemestre }}</span>
            </p>
            <p
              style="color: #4c4c4c"
              class="mb-1 subtitle-2 font-weight-medium"
            >
              Turno: <span class="caption">{{ group.nombreTurno }}</span>
            </p>
            <p
              style="color: #4c4c4c"
              class="mb-1 subtitle-2 font-weight-medium"
            >
              Costo: <span class="caption">{{ group.costo }} Bs</span>
            </p>
            <p
              style="color: #4c4c4c"
              class="mb-1 subtitle-2 font-weight-medium"
            >
              Fecha de Inicio:
              <span class="caption">{{ formatDate(group.fechaInicio) }}</span>
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-toolbar flat class="mt-2">
      <v-toolbar-title
        >Estudiantes Inscritos {{ this.students.length }}</v-toolbar-title
      >
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Buscar"
        single-line
        hide-details
        dense
        outlined
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn color="primary" dark @click="searchStudent = !searchStudent">
        <span class="text-capitalize text-body">{{
          searchStudent ? "Cerrar" : "Inscribir"
        }}</span>
      </v-btn>
    </v-toolbar>
    <v-card v-show="searchStudent" outlined tile>
      <v-card-text class="mt-1">
        <p class="font-weight-bold text-body-1" style="color: #1ca5db">
          Buscar Estudiante
        </p>
        <v-form ref="formSearchStudent">
          <v-row>
            <v-col cols="12" sm="2" md="2" class="py-0">
              <p class="mb-2">Opciones</p>
              <v-select
                v-model="formSearchStudent.option"
                :items="optionItems"
                item-text="option"
                item-value="idOption"
                label="Seleccione una opcion"
                persistent-hint
                return-object
                outlined
                single-line
                dense
                @change="optionChange"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <p class="mb-2">{{ optionName }}</p>
              <v-text-field
                v-model="formSearchStudent.value"
                class="pt-0"
                :placeholder="optionName"
                single-line
                dense
                outlined
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0 d-flex align-center">
              <v-row>
                <v-btn
                  class="ml-2"
                  dark
                  color="#1CA5DB"
                  elevation="0"
                  @click="searchStudentByParams"
                >
                  <span class="text-capitalize text-body">Buscar</span>
                </v-btn>
                <v-btn
                  class="ml-2"
                  dark
                  color="#1CA5DB"
                  elevation="0"
                  outlined
                  @click="dialog = true"
                >
                  <span class="text-capitalize text-body"
                    >Nuevo Estudiante</span
                  >
                </v-btn>
              </v-row>
            </v-col>
            <!--v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="py-0"
                        >
                            <p class="mb-2">Apellidos</p>
                            <v-text-field
                                v-model="formSearchStudent.surnames"
                                class="pt-0"
                                placeholder="Apellidos"
                                single-line
                                dense
                                outlined
                            >
                            </v-text-field>
                        </v-col-->
            <!--v-col
                            cols="12"
                            sm="4"
                            md="4"
                            class="py-0"
                        >
                            <p class="mb-2">Ci</p>
                            <v-text-field
                                v-model="formSearchStudent.ci"
                                class="pt-0"
                                placeholder="Ci"
                                single-line
                                dense
                                outlined
                            >
                            </v-text-field>
                        </v-col-->
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <template v-if="loadingSearch">
        <v-container class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </v-container>
      </template>
      <v-card-text v-show="searchStudents.length > 0">
        <p class="font-weight-bold">Lista estudiantes no inscritos</p>
        <v-data-table
          :headers="headersStudent"
          :items="searchStudents"
          item-key="idUsuario"
          class="elevation-1 font-weight-light caption"
          fixed-header
        >
          <template v-slot:[`item.accion`]="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="primary"
                  class="mr-2"
                  @click="enrollStudent(item)"
                  v-bind="attrs"
                  v-on="on"
                  :loading="loadingEnroll"
                >
                  <v-icon>mdi-account-arrow-right</v-icon>
                </v-btn>
              </template>
              <span>agregar al curso</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <p class="mb-2">Nombres</p>
                  <v-text-field
                    v-model="formStudent.names"
                    class="pt-0"
                    label="Nombres"
                    placeholder="Nombres"
                    single-line
                    dense
                    outlined
                    autocomplete="null"
                    :rules="nameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <p class="mb-2">Apellidos</p>
                  <v-text-field
                    v-model="formStudent.surnames"
                    class="pt-0"
                    label="Apellidos"
                    placeholder="Apellidos"
                    single-line
                    dense
                    outlined
                    autocomplete="null"
                    :rules="surnameRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <p class="mb-2">Ci</p>
                  <v-text-field
                    v-model="formStudent.ci"
                    class="pt-0"
                    label="Ci"
                    placeholder="Ci"
                    single-line
                    dense
                    outlined
                    autocomplete="null"
                    :rules="ciRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="6" class="py-0">
                  <p class="mb-2">Telefono</p>
                  <v-text-field
                    v-model="formStudent.phone"
                    class="pt-0"
                    label="Telefono"
                    placeholder="Telefono"
                    single-line
                    dense
                    outlined
                    autocomplete="null"
                    :rules="phoneRules"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" class="py-0">
                  <p class="mb-2">Email</p>
                  <v-text-field
                    v-model="formStudent.email"
                    class="pt-0"
                    label="Email"
                    placeholder="Email"
                    single-line
                    dense
                    autocomplete="null"
                    outlined
                  >
                  </v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="6"
                  class="py-0"
                  v-if="user.idRol == 1"
                >
                  <p class="mb-2">Sucursal</p>
                  <v-select
                    v-model="formStudent.idBranchoffice"
                    :items="branchoffices"
                    item-text="nombreSucursal"
                    item-value="idSucursal"
                    label="Sucursal"
                    persistent-hint
                    dense
                    outlined
                    single-line
                    :rules="branchofficeRules"
                  ></v-select>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            :disabled="loadingData"
            @click="close"
          >
            <span class="text-capitalize">Cancelar</span>
          </v-btn>
          <v-btn
            color="blue darken-1"
            dark
            :loading="loadingData"
            @click="saveStudent"
          >
            <span class="text-capitalize">Guardar</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container v-if="loading" class="d-flex justify-center">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </v-container>
    <template v-if="students.length > 0">
      <v-data-table
        :search="search"
        :headers="user.idRol == 1 ? headersAdmin : headers"
        :items="students"
        item-key="idGrupoUsuario"
        class="elevation-1 font-weight-light caption"
        fixed-header
      >
        <template v-slot:[`item.estado`]="{ item }">
          {{ item.estado ? "Activo" : "Inactivo" }}
        </template>
        <template v-slot:[`item.accion`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="success"
                class="mr-2"
                @click="viewPayments(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-account-cash</v-icon>
              </v-btn>
            </template>
            <span>Ver pagos</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="user.idRol == 1"
                icon
                color="warning"
                class="mr-2"
                @click.stop="createCredential(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-badge-account</v-icon>
              </v-btn>
            </template>
            <span>Credencial</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="grey"
                class="mr-2"
                @click.stop="viewGrades(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-note-text</v-icon>
              </v-btn>
            </template>
            <span>Ver boletin</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                class="mr-2"
                @click.stop="editItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                :color="item.estado ? 'error' : 'success'"
                class="mr-2"
                @click="deleteItem(item)"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon v-if="user.idRol == 1">{{
                  item.estado ? "mdi-cancel" : "mdi-check"
                }}</v-icon>
                <v-icon v-else>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span v-if="user.idRol == 1">{{
              item.estado ? "Desactivar" : "Activar"
            }}</span>
            <span v-else>Eliminar</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </template>
    <template v-else>
      <v-card elevation="0" outlined v-if="loading == false">
        <v-card-text class="text-center pb-0"
          >No existen Estudiantes inscritos al curso.</v-card-text
        >
        <v-card-text class="text-center pt-0"
          ><v-btn
            color="blue darken-1"
            dark
            small
            class="my-2 px-2"
            tile
            elevation="0"
            @click="selectCourseStudents"
            >Volver a Intentar</v-btn
          ></v-card-text
        >
      </v-card>
    </template>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <p style="color: #1874bf" class="text-h6 text-center pt-4">
          Informe de Grupo
        </p>
        <v-card-text v-if="user.idRol == 1" class="text-center">
          {{
            `¿Esta seguro de ${
              editedItem.estado ? "desactivar" : "activar"
            } el estudiante ${editedItem.nombres} del curso ${
              group.nombreGrupo
            }?`
          }}
        </v-card-text>
        <v-card-text v-else class="text-center">
          ¿Esta seguro de eliminar el estudiante {{ editedItem.nombres }} del
          curso {{ group.nombreGrupo }}?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">No</v-btn>
          <v-btn dark color="blue darken-1" @click="deleteItemConfirm"
            >Si</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTransfer" max-width="400px">
      <v-card>
        <p style="color: #1874bf" class="text-h6 text-center pt-4">
          Informe de Transferencia
        </p>
        <v-card-text class="text-center">
          {{ message }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeTransfer">No</v-btn>
          <v-btn dark color="blue darken-1" @click="transferConfirm">Si</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="colorSnackbar" text>
      <span class="text-center">{{ textSnackbar }}</span>
    </v-snackbar>
    <v-dialog v-model="dialogCredential" max-width="700px">
      <v-card>
        <v-card-text class="text-center pb-0">
          <v-row class="mt-0">
            <v-col cols="12" sm="8" md="8">
              <p style="color: #1874bf" class="text-h6 text-center pt-2 mb-0">
                Datos Estudiante
              </p>
              <v-row class="mt-2">
                <v-col cols="6">
                  <v-img
                    v-show="qrUser.avatar != null"
                    :src="qrUser.avatar"
                    width="160"
                    contain
                  >
                  </v-img>
                  <v-row justify="end">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-show="qrUser.avatar != null"
                          class="ma-2"
                          text
                          icon
                          color="red"
                          v-on="on"
                          v-bind="attrs"
                          :loading="loadingData"
                          @click.stop="rejectedAvatar"
                        >
                          <v-icon>mdi-delete</v-icon>
                        </v-btn>
                      </template>
                      <span>Rechazar Fotografia</span>
                    </v-tooltip>
                  </v-row>

                  <v-icon v-show="qrUser.avatar == null" class="mt-3" size="50"
                    >mdi-image-off</v-icon
                  >
                  <p class="mt-3" v-show="qrUser.avatar == null">
                    El estudiante no subio la fotografia
                  </p>
                </v-col>
                <v-col cols="6" align-self="center">
                  <p class="mb-0 font-weight-bold text-left">
                    Nombres:
                    <span class="font-weight-regular">{{
                      qrUser.nombres
                    }}</span>
                  </p>
                  <p class="mb-0 font-weight-bold text-left">
                    Apellidos:
                    <span class="font-weight-regular">{{
                      qrUser.apellidos
                    }}</span>
                  </p>
                  <p class="mb-0 font-weight-bold text-left">
                    C.I.:
                    <span class="font-weight-regular">{{ qrUser.ci }}</span>
                  </p>
                  <p class="mb-0 font-weight-bold text-left">
                    Telefono:
                    <span class="font-weight-regular">{{
                      qrUser.telefono
                    }}</span>
                  </p>
                  <p class="mb-0 font-weight-bold text-left">
                    Grupo:
                    <span class="font-weight-regular">{{
                      group.nombreGrupo
                    }}</span>
                  </p>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <p style="color: #1874bf" class="text-h6 text-center pt-2 mb-0">
                QR Generado
              </p>
              <v-container
                v-if="loadingQr"
                class="d-flex justify-center align-center"
                style="height: 180px"
              >
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </v-container>
              <vue-qr
                v-show="qrCode != '123'"
                :text="qrCode"
                :size="200"
                :dotScale="1"
              ></vue-qr>
              <p
                class="red--text"
                v-if="
                  qrCode ==
                  '5fKEh8C/jDq0NH4A2sMK1kfV4yUQ8XznCZ3VBQo/KQr8fXfzBYlF/Huv2vSNcM5OeIf30AuT+bFrgeQwxJ8adw=='
                "
              >
                QR invalido, vuelva generar
              </p>
              <credentialGenerateUp
                ref="credentialUp"
                :user="qrUser"
                :group="group"
              />
              <v-row justify="center">
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ma-2"
                      color="primary"
                      text
                      icon
                      v-on="on"
                      v-bind="attrs"
                      :loading="loadingData"
                      @click.stop="generateQR"
                    >
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </template>
                  <span>Volver a generar QR</span>
                </v-tooltip>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            class="white--text"
            :disabled="qrUser.avatar == null ? true : false"
            elevation="0"
            color="blue darken-1"
            @click="generateCredential"
            >Descargar Credencial</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogGrades" max-width="750">
      <v-card>
        <v-card-title style="color: #1874bf"
          >Boletin de calificaciones
          <v-spacer />
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="studentGrade.length > 0"
                class="ml-3 mr-2"
                color="primary"
                fab
                depressed
                small
                dark
                v-bind="attrs"
                v-on="on"
                @click="printGrade"
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </template>
            <span>Imprimir Boletin</span>
          </v-tooltip>
        </v-card-title>
        <v-card-text>
          <v-container v-if="loadingGrades" class="d-flex justify-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </v-container>
          <template v-if="studentGrade.length > 0">
            <v-data-table
              :headers="headersGrade"
              :items="studentGrade"
              hide-default-footer
              item-key="idGrupoMateria"
              class="elevation-1 font-weight-light caption"
              fixed-header
            >
            </v-data-table>
          </template>
        </v-card-text>
      </v-card>
    </v-dialog>
    <DialogDownload :dialog="dialogDownload" />
    <ReportStudentGrade
      v-show="false"
      ref="reportGrade"
      @downloadStop="dialogDownloadStop"
      :headers="headersPrint"
      :group="group"
      :student="studentData"
      :grades="studentGradePrint"
      :assistance="attendanceStudent"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import vueQr from "vue-qr";
import { mapState } from "vuex";
import credentialGenerateUp from "@/components/credential/credential-generate-user-component.vue";
import ReportStudentGrade from "@/components/report/report-student-grade-component.vue";
import DialogDownload from "@/components/dialog/dialog-download-component.vue";

export default {
  components: {
    vueQr,
    credentialGenerateUp,
    ReportStudentGrade,
    DialogDownload,
  },
  data() {
    return {
      semester: {},
      group: {},
      headers: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Telefono",
          value: "telefono",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        //{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      headersAdmin: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Telefono",
          value: "telefono",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Estado",
          value: "estado",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        //{text: "Usuario", value: "usuario", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        //{text: "Password", value: "password", align: 'left', sortable: false, class: 'font-weight-bold blue-grey lighten-5'},
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      headersStudent: [
        {
          text: "Ci",
          value: "ci",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Apellidos",
          value: "apellidos",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Nombres",
          value: "nombres",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Acciones",
          value: "accion",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
      ],
      students: [],
      items: [
        {
          text: "Semestre",
          disabled: false,
          href: "/semester",
        },
        {
          text: "Grupos",
          disabled: false,
          href: `/semester/${this.$route.params.idSemester}/group`,
        },
      ],
      loading: false,
      loadingData: false,
      loadingSearch: false,
      snackbar: false,
      colorSnackbar: "",
      textSnackbar: "",
      searchStudent: false,
      dialog: false,
      formSearchStudent: {
        names: "",
        surnames: "",
        ci: "",
        value: "",
        option: {
          idOption: 1,
          option: "CI",
        },
      },
      formStudent: {
        idStudent: "",
        names: "",
        surnames: "",
        ci: "",
        email: "",
        phone: "",
        idBranchoffice: "",
        creationDate: new Date().toISOString().substring(0, 10),
      },
      nameRules: [(value) => !!value || "Ingrese nombres"],
      surnameRules: [(value) => !!value || "Ingrese apellidos"],
      ciRules: [(value) => !!value || "Ingrese un ci"],
      emailRules: [
        (value) => !!value || "Ingrese un email",
        (value) => /.+@.+\..+/.test(value) || "Debe ingresar un email correcto",
      ],
      phoneRules: [
        (value) => !!value || "Ingrese un numero de telefono",
        (value) =>
          /^(\d{8}-\d{8}|\d{8})$/.test(value) ||
          "Debe ingresar un formato correcto",
      ],
      branchofficeRules: [(value) => !!value || "Seleccione una sucursal"],
      branchoffices: [],
      searchStudents: [],
      dialogDelete: false,
      editedItem: "",
      editedIndex: -1,
      optionItems: [
        {
          idOption: 1,
          option: "CI",
        },
        {
          idOption: 2,
          option: "Apellidos",
        },
        {
          idOption: 3,
          option: "Nombres",
        },
      ],
      optionName: "CI",
      //transferStudent
      dialogTransfer: false,
      userData: {},
      message: "",
      idBranchofficeTransfer: "",
      loadingEnroll: false,
      dialogCredential: false,
      qrCode: "123",
      qrUser: {},
      selectedUser: {},
      loadingQr: false,
      dialogGrades: false,
      studentGrade: [],
      studentGradePrint: [],
      headersGrade: [],
      headersPrint: [],
      loadingGrades: false,
      dialogDownload: false,
      studentData: {},
      search: "",
      attendanceStudent: {},
    };
  },
  computed: {
    ...mapState(["idDepartament", "user"]),
    nombreGrupo() {
      return this.group.nombreGrupo;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Estudiante" : "Editar Estudiante";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogCredential(val) {
      val || this.closeCredential();
    },
    dialogGrades(val) {
      val || this.closeGrade();
    },
  },
  created() {
    this.selectGroupById();
    this.selectCourseStudents();
    this.selectSemesterById();
    this.selectBranchoffices();
  },
  methods: {
    //grades
    dialogDownloadStop() {
      this.dialogDownload = false;
    },
    printGrade() {
      this.dialogDownload = true;
      this.$refs.reportGrade.generatePdf();
    },
    closeGrade() {
      this.dialogGrades = false;
      this.studentGrade = [];
      this.studentGradePrint = [];
      this.headersGrade = [];
      this.headersPrint = [];
      this.attendanceStudent = {};
    },
    async selectGradeStudentByIdGroup(idStudent) {
      this.studentGrade = [];
      this.studentGradePrint = [];
      try {
        let response = await axios.get(
          `students/selectGradeStudentByIdGroup/${this.group.idGrupo}`,
          { params: { idStudent: idStudent } }
        );
        if (response.status == 200) {
          if (response.data.subjects.length > 0) {
            this.studentGradePrint = response.data.subjects;
            response.data.subjects.map((subject, index) => {
              subject.nro = index + 1;
            });
            this.studentGrade = response.data.subjects;
          } else {
            this.notification("warning", "No se registro las materias");
          }
          this.loadingGrades = false;
        }
      } catch (error) {
        console.log(error);
        this.loadingGrades = false;
      }
    },
    defineHeaders() {
      this.headersPrint.push({
        text: "Materia",
        value: "nombreMateria",
        align: "left",
        sortable: false,
        class: "font-weight-bold blue-grey lighten-5",
      });
      this.headersGrade.push(
        {
          text: "Nro",
          value: "nro",
          align: "center",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        },
        {
          text: "Materia",
          value: "nombreMateria",
          align: "left",
          sortable: false,
          class: "font-weight-bold blue-grey lighten-5",
        }
      );
      for (let i = 0; i < this.group.cuotas; i++) {
        this.headersGrade.push(
          {
            text: `Simulacro ${i + 1}`,
            value: `sim${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          },
          {
            text: `Examen ${i + 1}`,
            value: `exa${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          }
        );
        this.headersPrint.push(
          {
            text: `Simulacro ${i + 1}`,
            value: `sim${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          },
          {
            text: `Examen ${i + 1}`,
            value: `exa${i + 1}`,
            align: "center",
            sortable: false,
            class: "font-weight-bold blue-grey lighten-5",
          }
        );
      }
    },
    viewGrades(item) {
      console.log(item);
      this.dialogGrades = true;
      this.studentData = {};
      this.studentData = Object.assign({}, item);
      this.defineHeaders();
      this.loadingGrades = true;
      this.selectGradeStudentByIdGroup(item.idUsuario);
      this.selectAttendanceStudent(this.group.idGrupo, item.idUsuario);
    },
    async selectAttendanceStudent(idGroup, idUser) {
      try {
        let response = await axios.get(
          `attendances/selectAttendanceStudent/${idGroup}/${idUser}`
        );
        if (response.status == 200) {
          console.log(response.data);
          this.attendanceStudent = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    //avatar
    async updateRejectedAvatar(id) {
      try {
        let response = await axios.put(`users/rejectedAvatar/${id}`);
        if (response.status == 200) {
          this.notification("warning", response.data.msg);
          this.selectCourseStudents();
          this.qrUser.avatar = null;
          this.loadingData = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingData = false;
      }
    },
    rejectedAvatar() {
      this.loadingData = true;
      this.updateRejectedAvatar(this.qrUser.idUsuario);
    },
    //credential
    generateCredential() {
      this.$refs.credentialUp.generatePdf();
      //this.$refs.credentialDown.generatePdf();
    },
    async updateQrCode(user) {
      try {
        let response = await axios.put("courses/updateQrCode", user);
        if (response.status == 200) {
          this.notification("success", response.data.msg);
          this.loadingQr = false;
          console.log(response.data);
          return response.data.data;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingQr = false;
        return null;
      }
    },
    async generateQR() {
      this.loadingQr = true;
      this.qrCode = "123";
      const data = {
        idUser: this.selectedUser.idUsuario,
        idGroup: this.selectedUser.idGrupo,
        ci: this.selectedUser.ci,
      };
      const user = await this.updateQrCode(data);
      if (user != null) {
        this.qrCode = user.qrCode;
        this.qrUser = user;
      }
    },
    async createCredential(item) {
      this.selectedUser = item;
      if (item.qrCode != null) {
        this.dialogCredential = true;
        this.qrCode = item.qrCode;
        this.qrUser = item;
      } else {
        const data = {
          idUser: item.idUsuario,
          idGroup: item.idGrupo,
          ci: item.ci,
        };
        this.dialogCredential = true;
        this.loadingQr = true;
        this.qrUser = this.selectedUser;
        const user = await this.updateQrCode(data);
        if (user != null) {
          this.qrCode = user.qrCode;
          this.qrUser = user;
        }
      }
    },
    closeCredential() {
      this.dialogCredential = false;
      this.qrCode = "123";
      this.qrUser = { qrCode: "123" };
      this.selectedUser = {};
    },
    //transferStudent
    async updateTransferUser(data) {
      try {
        let response = await axios.put("users/updateTransferUser", data);
        if (response.status == 200) {
          this.searchStudents.push(this.userData);
          this.closeTransfer();
          this.notification("success", response.data["msg"]);
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    transferConfirm() {
      let data = {
        idBranchoffice: this.idBranchofficeTransfer,
        idUser: this.userData.idUsuario,
      };

      this.updateTransferUser(data);
    },
    closeTransfer() {
      this.dialogTransfer = false;
      this.userData = {};
      this.message = "";
      this.idBranchofficeTransfer = "";
    },
    //otherModule
    optionChange(evt) {
      this.optionName = evt.option;
      this.formSearchStudent.value = "";
    },
    async deleteEnrollStudent(data) {
      try {
        let response = await axios.put(`courses/updateCourseStudent`, data);
        if (response.status == 200) {
          this.closeDelete();
          this.notification(
            "warning",
            this.user.idRol == 1
              ? response.data["msg"]
              : "Se elimino el estudiante"
          );
          this.selectCourseStudents();
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async insertCourseStudent(data) {
      try {
        let response = await axios.post("courses/insertCourseStudent", data);
        if (response.status == 200) {
          this.notification("success", response.data["msg"]);
          //this.selectCourseStudents();
          this.formSearchStudent.names = "";
          this.formSearchStudent.surnames = "";
          this.formSearchStudent.ci = "";
          this.searchStudents = [];
          this.loadingEnroll = false;
        }
      } catch (error) {
        console.log(error);
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
        this.loadingEnroll = false;
      }
    },
    enrollStudent(item) {
      this.loadingEnroll = true;
      let student = {
        apellidos: item.apellidos,
        ci: item.ci,
        email: item.email,
        estado: true,
        fechaInscripcion: "",
        idGrupo: this.group.idGrupo,
        idGrupoUsuario: 0,
        idSucursal: item.idSucursal,
        idUsuario: item.idUsuario,
        nombres: item.nombres,
        telefono: item.telefono,
      };
      let data = {
        registrationDate: this.formStudent.creationDate,
        idGroup: this.group.idGrupo,
        idUser: item.idUsuario,
      };

      this.insertCourseStudent(data);
      this.students.splice(0, 0, student);
    },
    async insertStudent(data) {
      this.searchStudents = [];
      try {
        let response = await axios.post("users/insertStudent", data);
        if (response.status == 200) {
          if (
            response.data.type == 0 ||
            response.data.type == 1 ||
            response.data.type == 2
          ) {
            this.close();
            this.loadingData = false;
            this.notification("success", response.data["msg"]);
            this.formSearchStudent.value = "";
            this.searchStudents.push(response.data["data"]);
          } else {
            this.idBranchofficeTransfer = this.formStudent.idBranchoffice;
            this.close();
            this.loadingData = false;
            this.userData = response.data.data;
            this.message = response.data.msg;
            this.dialogTransfer = true;
            this.formSearchStudent.value = "";
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    async updateStudent(data) {
      try {
        let response = await axios.put("users/updateStudent", data);
        if (response.status == 200) {
          this.close();
          this.loadingData = false;
          this.notification("success", response.data["msg"]);
          this.selectCourseStudents();
        }
      } catch (error) {
        console.log(error);
        this.loadingData = false;
        this.notification(
          "error",
          "Ocurrio algo inesperado. Por favor intente de nuevo."
        );
      }
    },
    saveStudent() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          let data = {
            idStudent: this.formStudent.idStudent,
            names: this.formStudent.names.toUpperCase().trim(),
            surnames: this.formStudent.surnames.toUpperCase().trim(),
            ci: this.formStudent.ci.trim(),
            email: this.formStudent.email.trim(),
            phone: this.formStudent.phone.trim(),
            idBranchoffice: this.formStudent.idBranchoffice,
          };
          this.loadingData = true;

          this.updateStudent(data);
        } else {
          let data = {
            names: this.formStudent.names.toUpperCase().trim(),
            surnames: this.formStudent.surnames.toUpperCase().trim(),
            ci: this.formStudent.ci.trim(),
            email: this.formStudent.email.trim(),
            phone: this.formStudent.phone.trim(),
            idBranchoffice:
              this.user.idRol == 1
                ? this.formStudent.idBranchoffice
                : this.user.idSucursal,
            user: null,
            pass: null,
            idDepartament: this.idDepartament,
          };
          this.loadingData = true;

          this.insertStudent(data);
        }
      }
    },
    async selectSemesterById() {
      try {
        let response = await axios.get(
          `semesters/selectSemesterById/${this.$route.params.idSemester}`
        );
        if (response.status == 200) {
          this.semester = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectGroupById() {
      try {
        let response = await axios.get(
          `groups/selectGroupById/${this.$route.params.idGroup}`
        );
        if (response.status == 200) {
          this.group = response.data;
          this.items.push({
            text: this.group.nombreGrupo,
            disabled: true,
            href: "/",
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async selectSearchStudent(url) {
      try {
        let response = await axios.get(`users/selectSearchStudent/${url}`);
        if (response.status == 200) {
          this.searchStudents = response.data;
          this.loadingSearch = false;
          if (response.data.length <= 0) {
            this.notification("primary", "No se encontro registros");
          }
        }
      } catch (error) {
        console.log(error);
        this.loadingSearch = false;
      }
    },
    searchStudentByParams() {
      let names = "";
      let surnames = "";
      let ci = "";
      if (this.formSearchStudent.option.idOption == 1) {
        names = null;
        surnames = null;
        ci =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
      } else if (this.formSearchStudent.option.idOption == 2) {
        names = null;
        surnames =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        ci = null;
      } else if (this.formSearchStudent.option.idOption == 3) {
        names =
          this.formSearchStudent.value == ""
            ? null
            : this.formSearchStudent.value;
        surnames = null;
        ci = null;
      }

      let url = `${this.idDepartament}/${this.$route.params.idGroup}/${names}/${surnames}/${ci}`;
      this.loadingSearch = true;
      this.selectSearchStudent(url);
    },
    async selectCourseStudents() {
      let url =
        this.user.idRol == 1
          ? `courses/selectCourseStudentsByIdGroup/${this.$route.params.idGroup}/null`
          : `courses/selectCourseStudentsByIdGroup/${this.$route.params.idGroup}/1`;
      this.loading = true;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.students = response.data;
          this.loading = false;
        }
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
    async selectBranchoffices() {
      let url = `branchoffices/selectBranchoffices/${this.idDepartament}/1`;
      try {
        let response = await axios.get(url);
        if (response.status == 200) {
          this.branchoffices = response.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    notification(type, text) {
      this.snackbar = true;
      this.colorSnackbar = type;
      this.textSnackbar = text;
    },
    editItem(item) {
      this.editedIndex = 1;
      this.editedItem = Object.assign({}, item);
      this.formStudent.idStudent = this.editedItem.idUsuario;
      this.formStudent.names = this.editedItem.nombres;
      this.formStudent.surnames = this.editedItem.apellidos;
      this.formStudent.ci = this.editedItem.ci;
      this.formStudent.email = this.editedItem.email;
      this.formStudent.phone = this.editedItem.telefono;
      this.formStudent.idBranchoffice = this.editedItem.idSucursal;
      this.dialog = true;
    },
    viewPayments(item) {
      this.$router.push(
        `/semester/${this.$route.params.idSemester}/group/${this.$route.params.idGroup}/student/${item.idUsuario}`
      );
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      let data = {
        idGroup: this.group.idGrupo,
        idUser: this.editedItem.idUsuario,
        state: this.editedItem.estado ? 0 : 1,
      };
      this.deleteEnrollStudent(data);
    },
    close() {
      this.dialog = false;
      this.editedIndex = -1;
      this.formStudent.ci = "";
      this.formStudent.email = "";
      this.formStudent.idBranchoffice = "";
      this.formStudent.idStudent = "";
      this.formStudent.names = "";
      this.formStudent.phone = "";
      this.formStudent.surnames = "";
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.editedItem = "";
    },
    formatDate(date) {
      if (!date) return null;
      let dateSub = date.substring(0, 10);
      const [year, month, day] = dateSub.split("-");
      return `${day}/${month}/${year}`;
    },
  },
};
</script>

<style>
</style>